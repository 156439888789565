import request from '@/utils/request'


// 查询笔记列表
export function listNote(query) {
  return request({
    url: '/note/note/list',
    method: 'get',
    params: query
  })
}

// 查询笔记分页
export function pageNote(query) {
  return request({
    url: '/note/note/page',
    method: 'get',
    params: query
  })
}

// 查询笔记详细
export function getNote(data) {
  return request({
    url: '/note/note/detail',
    method: 'get',
    params: data
  })
}

// 新增笔记
export function addNote(data) {
  return request({
    url: '/note/note/add',
    method: 'post',
    data: data
  })
}

// 修改笔记
export function updateNote(data) {
  return request({
    url: '/note/note/edit',
    method: 'post',
    data: data
  })
}

// 删除笔记
export function delNote(data) {
  return request({
    url: '/note/note/delete',
    method: 'post',
    data: data
  })
}
